import MktSection from '@/views/layouts/SubLayouts/MktSection.vue';
//*********** Hiring */
import ImageReportCategory from '@/views/MktSection/pages/ImageReportCategory/Index';
import MktEmployeeReport from '@/views/MktSection/pages/EmployeeReport/Index';
import ProductCodeRequest from '@/views/MktSection/pages/ProductCodeRequest/Index';
import MktImageReport from '@/views/MktSection/pages/ImageReport/Index';
// import UserList from '@/views/ItSection/pages/User/UserList';
// import ItScheduleList from '@/views/ItSection/pages/ItSchedule/ItScheduleList';

export default [
    {
      path: 'mkt-section',
      name: 'MktSection',
      component: MktSection,
      children:[
        {
            path: 'image-report-category',
            name: 'ImageReportCategory',
            component: ImageReportCategory,
        },
        {
            path: 'product-code-request',
            name: 'ProductCodeRequest',
            component: ProductCodeRequest,
        },
        {
            path: 'mkt-image-report',
            name: 'MktImageReport',
            component: MktImageReport,
        },
        {
          path: 'mkt-employee-report',
          name: 'MktEmployeeReport',
          component: MktEmployeeReport,
          meta: {
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              }
          ],
          }
        },
      ]
    },
    
  ]
