<template>
    <div class="leave-index" >
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog.state=true" :icon="icon"></myBreadCrumb> -->
        <!-- <create-dialog v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog> -->

        <v-card v-if="true">
            <v-card-title>
                <v-spacer></v-spacer>
                <h3>Phiếu lương</h3>
                <v-spacer></v-spacer>
                <!-- <v-btn small color="teal darken-4" dark @click="dialog.state=true">
                    <v-icon small left>mdi-upload</v-icon> Upload excel
                </v-btn> -->
                <!-- <create-button @click="dialog.state=true" ></create-button> -->
                <!-- <v-divider vertical class="mx-2"></v-divider> -->
                <!-- <month-picker width="7rem" v-model="month" label="Chọn tháng" outlined dense></month-picker> -->
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData"></data-filter> -->
            </v-card-title>
            <v-card-subtitle class="text-center">
                <b>Tháng:</b> 12-2024
            </v-card-subtitle>
            <v-card-text class="primary--text">
                <v-row style="max-width: 800px;">
                    <v-col cols="6">
                        <table class="" style="width: 100%;">
                            <tr>
                                <td class="font-weight-bold">Họ và tên: </td>
                                <td>{{ item.ten }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Chi nhánh/Phòng ban: </td>
                                <td>{{ item.chiNhanh }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Chức vụ/Vị trí ban: </td>
                                <td>{{ item.chucVu }}</td>
                            </tr>
                        </table>
                    </v-col>
                    <v-col cols="6">
                        <table class="" style="width: 100%;">
                            <tr>
                                <td class="font-weight-bold">Mã NS: </td>
                                <td>{{ item.maNS }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Bộ phận: </td>
                                <td>{{ item.boPhan }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Tại ngân hàng: </td>
                                <td>{{ item.nganHang }}</td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-simple-table style="border: 1px solid; border-collapse: collapse;">
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">Số giờ công cơ bản</td>
                                    <td>{{ formatNumber(item.gcCoBan + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Số giờ công thực tế</td>
                                    <td>{{ formatNumber(item.gcThucTe + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Giờ làm thêm</td>
                                    <td>{{ formatNumber(item.gcLamThem + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Giờ tính tăng ca</td>
                                    <td>{{ formatNumber(item.gcTangCa + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Giờ lễ/tết</td>
                                    <td>{{ formatNumber(item.gcLeTet + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold orange darken-2 white--text" colspan="2" >Các khoản khấu trừ</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Tạm ứng cơm/lương</td>
                                    <td>{{ formatNumber(item.ktTamUng + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">BHXH</td>
                                    <td>{{ formatNumber(item.ktBHXH + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Thuế TNCN</td>
                                    <td>{{ formatNumber(item.ktTNCN + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Truy thu/Thu hồi</td>
                                    <td>{{ formatNumber(item.ktTruyThu + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold red--text">Tổng khấu trừ (1)</td>
                                    <td class="red--text">{{formatNumber(Number(item.tongKhauTru + 0.0) )}}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-simple-table style="border: 1px solid; border-collapse: collapse;">
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">Lương cơ bản</td>
                                    <td>{{ formatNumber(item.lCoBan + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Lương thực tế</td>
                                    <td>{{ formatNumber(item.lThucTe + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Lương làm thêm</td>
                                    <td>{{ formatNumber(item.lLamThem + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Lương tăng ca</td>
                                    <td>{{ formatNumber(item.lTangCa + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Lương Lễ/Tết</td>
                                    <td>{{ formatNumber(item.lLeTet + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Phụ cấp khu vực</td>
                                    <td>{{ formatNumber(item.pcKhuVuc + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Phụ cấp đào tạo</td>
                                    <td>{{ formatNumber(item.pcDaoTao + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Phụ cấp công việc</td>
                                    <td>{{formatNumber(item.pcCongTac + 0.0)}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Phụ cấp chuyên môn (DSPT)</td>
                                    <td>{{ formatNumber(item.pcChuyenMon + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Tiền ăn giữa ca</td>
                                    <td>{{ formatNumber(item.thuongTienAn + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Truy lãnh/Hoàn trả</td>
                                    <td>{{ formatNumber(item.thuongTruyLanh + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Lương Tháng 13 năm 2024 <br>
                                        => Xem mục (*) ở bên dưới </td>
                                    <td>{{ formatNumber(item.luong2413 + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Trợ cấp thôi việc</td>
                                    <td>{{ formatNumber(item.thoiViec + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold red--text">Tổng lương (2)</td>
                                    <td class="font-weight-bold red--text">{{ formatNumber(item.tongLuong + 0.0) }}</td>
                                </tr>
                                

                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-card outlined elevation="0" rounded="lg" class="mt-4" style="border: 2px solid black;">
                    <v-card-text>
                        <h2><span class="primary--text font-weight-bold">Lương thực nhận (2)-(1): </span> {{ formatNumber(item.thucNhan + 0.0)}}</h2>
                        <h2><span class="red--text font-weight-bold">Bằng chữ: </span> {{ formatNumber(item.bangChu)}}</h2>
                    </v-card-text>
                </v-card>
                <v-subheader class="orange--text text--darken-4 font-weight-medium">
                    <i>*Lưu ý: Do tính bảo mật về tiền lương nên Anh/Chị TBP/ASM cung cấp riêng cho từng cá nhân nhận lương. Mỗi cá nhân cam kết không tiết lộ thông tin tiền lương, nếu vi phạm sẽ xử lý theo quy định của Công ty.</i>
                </v-subheader>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-subheader class="font-weight-bold red--text text--darken-4">(*) Chi tiết Lương Tháng 13 năm 2024</v-subheader>
                        
                        <v-simple-table style="border: 1px solid; border-collapse: collapse;">
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">Trung bình lương năm 2024</td>
                                    <td>{{ formatNumber(item.luong24Avg + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Số ngày nghỉ không lương 2024</td>
                                    <td>{{ formatNumber(item.luong24Kl + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Số ngày làm việc thực tế</td>
                                    <td>{{ formatNumber(item.luong24Lv + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Lương tháng 13 2024</td>
                                    <td>{{ formatNumber(item.luong2413 + 0.0) }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-subheader class="font-weight-bold red--text text--darken-4">(**) Chi tiết chi bổ sung/ thu hồi Lương Tháng 13 năm 2024</v-subheader>
                        
                        <v-simple-table style="border: 1px solid; border-collapse: collapse;">
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">(1) Lương Tháng 13 năm 2024 đã chi ngày 08/01/2025</td>
                                    <td>{{ formatNumber(item.luong2413 + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">(2) Lương Tháng 13 năm 2024 điều chỉnh</td>
                                    <td>{{ formatNumber(item.luong24Dc + 0.0) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Lương Tháng 13 năm 2024 bổ sung/ thu hồi (2)-(1):</td>
                                    <td>{{ Number(item.luong24Th + 0.0) == 0.0 ? "-" : formatNumber(Number(item.luong24Th + 0.0)) }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-data-table height="600px"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
            
                <template v-slot:[`top`]>
                    <v-row class="mx-1 mb-4">
                        <v-col cols="6" md="4">
                            <v-text-field v-model="search" outlined dense label="Tìm kiếu theo tháng" placeholder="dd/mm/yyyy" hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </template>

                <template v-slot:[`item.month`]="{ item }">
                    {{formatDate(item.month)}}
                </template>

                <template v-slot:[`item.lCoBan`]="{ item }">
                    {{formatNumber(item.lCoBan)}}
                </template>

                <template v-slot:[`item.lThucTe`]="{ item }">
                    {{formatNumber(item.lThucTe)}}
                </template>

                <template v-slot:[`item.lLamThem`]="{ item }">
                    {{formatNumber(item.lLamThem)}}
                </template>
                
                <template v-slot:[`item.lTangCa`]="{ item }">
                    {{formatNumber(item.lTangCa)}}
                </template>

                <template v-slot:[`item.lLeTet`]="{ item }">
                    {{formatNumber(item.lLeTet)}}
                </template>

                <template v-slot:[`item.pcKhuVuc`]="{ item }">
                    {{formatNumber(item.pcKhuVuc)}}
                </template>

                <template v-slot:[`item.pcDaoTao`]="{ item }">
                    {{formatNumber(item.pcDaoTao)}}
                </template>

                <template v-slot:[`item.pcCongTac`]="{ item }">
                    {{formatNumber(item.pcCongTac)}}
                </template>

                <template v-slot:[`item.thuongQuy`]="{ item }">
                    {{formatNumber(item.thuongQuy)}}
                </template>

                <template v-slot:[`item.thuongTienAn`]="{ item }">
                    {{formatNumber(item.thuongTienAn)}}
                </template>

                <template v-slot:[`item.thuongTruyLanh`]="{ item }">
                    {{formatNumber(item.thuongTruyLanh)}}
                </template>

                <template v-slot:[`item.thucNhan`]="{ item }">
                    {{formatNumber(item.thucNhan)}}
                </template>

                <template v-slot:[`item.tongLuong`]="{ item }">
                    {{formatNumber(item.tongLuong)}}
                </template>

                <template v-slot:[`item.tongKhauTru`]="{ item }">
                    {{formatNumber(item.tongKhauTru)}}
                </template>
            </v-data-table> -->
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import CreateButton from '@/components/Crud/CreateButton';
import http from '@/components/services/http-common';
// import Create from './Create';
import DataFilter from '@/components/DataFilter.vue';

export default {
    name: 'Leave',
    components: {
        CreateButton,
        // "create-dialog": Create,
        "data-filter": DataFilter
    },
    watch: {
        tableParams: {
            handler (val) {
                this.fetchData();
                this.fetchCity();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month: {
            handler(val)
            {
                this.filters.from = moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to = moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },

    computed: {
        webAllowed() {
            let user = localStorage.getItem("user");
            let webAllowed = localStorage.getItem("WebAllowed");
            user = JSON.parse(user);
            return webAllowed.includes(user.employeeCode);
        },
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data() {
        return {
            ROOT_URL: "EmployeeSalaryImports/",

            //HEADER DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //filters
            month: moment().format("YYYY-MM"),
            filters:{
                code:null,
                employeeName:null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            rowCount: 0,

            //TABLE
            filterEmployeeCode:"",
            filterEmployeeName:"",
            filterDepartmentName:"",
            search: "",
            headers: [
                {text: "Tháng", value: "month",
                    filter: value => {
                        console.log(value.includes(moment(this.search).format("YYYY-MM-DD")));
                        if (!this.search) return true
            
                        return this.formatDate(value).includes(this.search)
                    },
                  },
                {text: "Mã NS", value: "maNS"},
                {text: "Tên NS", value: "ten"},
                {text: "Chức vụ", value: "chucVu"},
                {text: "Chi nhánh/Phòng ban", value: "chiNhanh",},
                {text: "STK", value: "stk"},
                {text: "Ngân Hàng", value: "nganHang"},
                {text: "Giờ công cơ bản", value: "gcCoBan"},
                {text: "Giờ công thực tế", value: "gcThucTe"},
                {text: "Giờ công làm thêm", value: "gcLamThem"},
                {text: "Giờ công tăng ca", value: "gcTangCa"},
                {text: "Giờ công lễ tết", value: "gcLeTet"},
                {text: "Khấu trừ tạm ứng", value: "ktTamUng"},
                {text: "Khấu trừ BHXH", value: "ktBHXH"},
                {text: "Khấu trừ TNCN", value: "ktTNCN"},
                {text: "Khấu trừ truy thu", value: "ktTruyThu"},
                {text: "Lương cơ bản", value: "lCoBan"},
                {text: "Lương thực tế", value: "lThucTe"},
                {text: "Lương làm thêm", value: "lLamThem"},
                {text: "Lương tăng ca", value: "lTangCa"},
                {text: "Lương lễ tết", value: "lLeTet"},
                {text: "Phụ cấp khu vực", value: "pcKhuVuc"},
                {text: "Phụ cấp đào tạo", value: "pcDaoTao"},
                {text: "Phụ cấp công tác", value: "pcCongTac"},
                {text: "Thưởng quý", value: "thuongQuy"},
                {text: "Thưởng tiền ăn", value: "thuongTienAn"},
                {text: "Thưởng truy lãnh", value: "thuongTruyLanh"},
                {text: "Tổng lương", value: "tongLuong"},
                {text: "Tổng khấu trừ", value: "tongKhauTru"},
                {text: "Thực nhận", value: "thucNhan"},
                {text: "Bằng chữ", value: "bangChu", width:"350px"},
            ],
                  
            items: [],
            item: {},
            options: {},
            isLoading: true,

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

        }
    },

    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatNumber(value){
            if(value == null)
                return ""
            return value.toLocaleString('en-US', { style: 'decimal' });
        },
        
        status(value) {
            let result = {};
            switch(value) {
                case 0: {
                    result.text = "Chờ duyệt";
                    result.color = "warning"
                    break;
                }
                case 1: {
                    result.text = "Đã duyệt"
                    result.color = "success"
                    break;
                }
                case 2: {
                    result.text = "Từ chối"
                    result.color = "error"
                    break;
                }
                default:{
                    result.text = "unknown"
                    break;
                }
            }
            return result;
        },

        //DATA JS
        fetchData () {
            let month = "2024-12-01"
            if(!!this.$route.query.id) {
                month = this.$route.query.id
            }
            return http.get(this.ROOT_URL + "employee/show", {
                params: {
                    month: moment(month).format("YYYY-MM-DD")
                }
            })
            .then(res => {
                // this.items = res.data.data
                this.item = res.data.data
                console.log(res.data.data)
                // if(this.items.length != 1) {
                //     alert("result error please reload page")
                // }
                this.isLoading = false;
            })
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {
            }
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "undo/" +this.targetItem.id).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

    },
    
    created() {
        this.fetchData();
    }
}
</script>