import CmSection from '@/views/layouts/SubLayouts/CmSection.vue';
//*********** Hiring */
import Dathang from '@/views/CmSection/DatHang/Index';
import CMProductCodeRequest from '@/views/MktSection/pages/ProductCodeRequest/Index';
// import ItScheduleList from '@/views/ItSection/pages/ItSchedule/ItScheduleList';

export default [
    {
      path: 'cm-section',
      name: 'CmSection',
      component: CmSection,
      children:[
        {
            path: 'dat-hang',
            name: 'DatHang',
            component: Dathang,
        },
        {
            path: 'cm-product-code-request',
            name: 'CMProductCodeRequest',
            component: CMProductCodeRequest,
        },
        
      ]
    },
    
  ]
