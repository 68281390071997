<template>
<v-card height="calc(100vh - 336px)" flat>
    <v-card-title>
        <v-spacer></v-spacer>
        Xin vui lòng đợi :.... {{redirectToURL}}
        <v-spacer></v-spacer>
        
    </v-card-title>
    <v-card-text class="text-center">
        <v-progress-circular class="my-12"
        :size="200"
        :width="7"
        color="orange"
        indeterminate
        ></v-progress-circular>
    </v-card-text>
</v-card>
</template>

<script>
import http from '@/components/services/http-common';
export default {
    props: ['id'],
    data(){
        return {
            redirected: false,
            redirectToURL: "muahangtest.trungsonpharma.com/account/login/internal?token=xxxxx"
        }
    },
    computed:{
        jwt() {
            let token = localStorage.getItem('token')
            return token
        }
    },
    methods:{
        redirectTo() {
            if(!this.redirected){
                
                window.location.href = `https://muahangtest.trungsonpharma.com/account/login/internal?token=${this.jwt}`;
                this.redirected =true;
            }
        }
    },
    mounted(){
        this.redirectTo();
    }
}
</script>
